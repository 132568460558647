import { rgb } from "pdf-lib";
import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {
  calculateTextPosition,
  formatText,
  getBottomYPosition,
} from "../../utils/utils";
import { analyzeTopSkillGaps } from "../../../algorithms/skillGapAnalyzer";
import promptProgress from "@/data/prompt/prompt_progress.json";

export const createSynthesisJobTemplate = async (
  pdfDoc,
  data,
  backgroundImageUrl,
  fonts,
  jobIndex = 0
) => {
  const jobTitle = data.results[jobIndex].metier.toUpperCase();
  const pagination = `${(jobIndex+1) * 5}`;
  const page = await createPageTemplate(
    pdfDoc,
    data,
    backgroundImageUrl,
{
        rightTitle: "SYNTHÈSE",
        leftTitle: formatText(jobTitle,24),
        pagination: pagination,
      }
  );

  const job = data.jobs.find(
    (job) => job.title === data.results[jobIndex].metier
  );
  const jobSignatures = job.signatures.find(
    (signature) => signature.quiz_code === data.quiz_code
  );
  const topSkills = analyzeTopSkillGaps(data.answers, jobSignatures);

  const score = data.results[jobIndex].score;
  const dimension = data.results[jobIndex].dimension;

  const synthesisText = formatText(generateSynthesisText(score, dimension),60);
  const improvementText = formatText(await generateImprovementText(topSkills));

  const synthesisTitleYPosition = layout.pageHeight - layout.margin - 160;
  drawTextElement(
    page,
    "SYNTHÈSE",
    fonts.titleFont,
    styles.titleStyle,
    layout.margin,
    synthesisTitleYPosition
  );

  const synthesisTextYPosition = getBottomYPosition(
    "SYNTHÈSE",
    fonts.titleFont,
    styles.titleStyle.size,
    synthesisTitleYPosition,
    styles.titleStyle.size * 1.33
  );
  drawTextElement(
    page,
    synthesisText,
    fonts.bodyFont,
    styles.bodyStyle,
    layout.margin,
    synthesisTextYPosition - 10
  );

  const improvementTitleYPosition =
    getBottomYPosition(
      synthesisText,
      fonts.bodyFont,
      styles.bodyStyle.size,
      synthesisTextYPosition - 40,
      styles.bodyStyle.size * 1.33
    ) - 80;
  drawTextElement(
    page,
    "AXES D'AMÉLIORATION",
    fonts.titleFont,
    styles.titleStyle,
    layout.margin,
    improvementTitleYPosition
  );

  drawTextElement(
    page,
    improvementText,
    fonts.bodyFont,
    styles.bodyStyle,
    layout.margin,
    improvementTitleYPosition - 60
  );

  return page;
};

const generateSynthesisText = (score, dimension) => {
  const baseText = "D'après tes résultats, ton profil [MATCH] avec ce métier.\n\n[DIMENSION]";

  const textScore = score < 70 ? "ne matche pas" : "matche";

  let textDimension = "";
  if (dimension > 120) {
    textDimension = "D'après notre analyse, le métier est sur-dimensionné pour ton profil, tu risques d'être challengé !";
  } else if (dimension < 90) {
    textDimension = "D'après notre analyse, le métier est sous-dimensionné pour ton profil, tu risques de t'ennuyer...";
  }
  else {
      textDimension = "D'après notre analyse, le métier est bien dimensionné pour ton profil !";
  }

  return baseText.replace("[MATCH]", textScore).replace("[DIMENSION]", textDimension);
}

const generateImprovementText = async (topSkills) => {
  const placeholders = topSkills.map((skill) => skill.skill).filter(Boolean);

  if (placeholders.length === 0) {
    const textProgressjobInfo =
      "Vous semblez avoir le niveau pour chacune des compétences essentielles de ce métier, gardez le cap, perfectionnez-les et n'hésitez pas à faire de la veille !";
    const resultProgressJobInfo = await fetch("/api/customJobInfoGPT", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: textProgressjobInfo,
        textPrompt: promptProgress,
      }),
    });
    const result = await resultProgressJobInfo.json();
    return result.data.content;
  }

  const messages = placeholders.slice(0, 3).map(async (placeholder, index) => {
    const improvementTexts = [
      `Tu sembles avoir une certaine ${placeholder}, mais il est essentiel de la faire progresser.`,
      `Tu devrais développer davantage ton ${placeholder} pour maximiser ta réussite.`,
      `Ta capacité en ${placeholder} semble en deçà des attentes du métier.`,
    ];

    const textProgressjobInfo = improvementTexts[index];
    const resultProgressJobInfo = await fetch("/api/customJobInfoGPT", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: textProgressjobInfo,
        textPrompt: promptProgress,
      }),
    });
    const result = await resultProgressJobInfo.json();
    return result.data.content;
  });

  const improvementMessages = await Promise.all(messages);
  return improvementMessages.join("\n\n");
};

const drawTextElement = (page, text, font, style, x, y) => {
  const { x: adjustedX, y: adjustedY } = calculateTextPosition(
    text,
    font,
    style.size,
    y,
    "left"
  );
  page.drawText(text, {
    x: adjustedX,
    y: adjustedY,
    size: style.size,
    font: font,
    color: style.color || rgb(35 / 255, 40 / 255, 58 / 255),
    lineHeight: style.size * 1.33,
  });
};
