import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {
    calculateTextPosition,
    formatText,
    getBottomYPosition,
} from "../../utils/utils";
import promptMission from "@/data/prompt/prompt_mission.json";
import promptTrendsMarket from "@/data/prompt/prompt_trends.json";
import promptCareer from "@/data/prompt/prompt_career.json";

export const createJobInfosTemplate = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    fonts,
    jobIndex = 0
) => {
    const pagination = `${((jobIndex + 1) * 8) - (jobIndex)}`;
    const jobTitle = data.results[jobIndex].metier.toUpperCase();
    const page = await createPageTemplate(
        pdfDoc,
        data,
        backgroundImageUrl,
{
        rightTitle: "FICHE MÉTIER",
        leftTitle: formatText(jobTitle,24),
        pagination: pagination,
        });

    const jobInfo = data.jobInfos.find(
        (job) => job.metierNom === data.results[jobIndex].metier
    );

    const leftColumnX = layout.margin;
    const rightColumnX = layout.pageWidth / 2;
    const columnWidth = (layout.pageWidth - 3 * layout.margin) / 2;
    const initialYPosition = layout.pageHeight - layout.margin - 200;

    // MISSION Section
    let currentYLeft = initialYPosition;
    drawTextElement(
        page,
        "MISSION",
        fonts.titleFont,
        styles.titleStyle,
        leftColumnX,
        currentYLeft
    );

    const textMissionjobInfo = `${jobInfo.metierNom} : ${jobInfo.mission}`;
    const resultMissionjobInfo = await fetch("/api/customJobInfoGPT", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            text: textMissionjobInfo,
            textPrompt: promptMission,
        }),
    });
    const resultMission = await resultMissionjobInfo.json();
    const textMission = formatText(resultMission.data.content, columnWidth / 10); // Ajustement pour largeur
    currentYLeft = drawTextElement(
        page,
        textMission,
        fonts.bodyFont,
        styles.bodyStyle,
        leftColumnX,
        currentYLeft - 30
    );

    // ACTIVITÉS Section
    currentYLeft -= 50; // Espace entre les sections
    drawTextElement(
        page,
        "ACTIVITÉS",
        fonts.titleFont,
        styles.titleStyle,
        leftColumnX,
        currentYLeft
    );

    const activityText = jobInfo.activities
        .map((activity) => `• ${formatText(activity, columnWidth / 10)}`)
        .join("\n");
    currentYLeft = drawTextElement(
        page,
        activityText,
        fonts.bodyFont,
        styles.bodyStyle,
        leftColumnX,
        currentYLeft - 30
    );

    // TENDANCE DU MARCHÉ Section (Right Column)
    let currentYRight = initialYPosition;
    drawTextElement(
        page,
        "TENDANCE DU MARCHÉ",
        fonts.titleFont,
        styles.titleStyle,
        rightColumnX,
        currentYRight
    );

    const resultTrendsjobInfo = await fetch("/api/customJobInfoGPT", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            text: jobInfo.trends,
            textPrompt: promptTrendsMarket,
        }),
    });
    const resultTrends = await resultTrendsjobInfo.json();
    const textTrends = formatText(resultTrends.data.content, columnWidth / 10); // Ajustement pour largeur
    currentYRight = drawTextElement(
        page,
        textTrends,
        fonts.bodyFont,
        styles.bodyStyle,
        rightColumnX,
        currentYRight - 30
    );

    // CHEMIN DE CARRIÈRE Section
    currentYRight -= 50; // Espace entre les sections
    drawTextElement(
        page,
        "CHEMIN DE CARRIÈRE",
        fonts.titleFont,
        styles.titleStyle,
        rightColumnX,
        currentYRight
    );

    const resultCareerjobInfo = await fetch("/api/customJobInfoGPT", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            text: jobInfo.careerPath,
            textPrompt: promptCareer,
        }),
    });
    const resultCareer = await resultCareerjobInfo.json();
    const careerPathText = formatText(resultCareer.data.content, columnWidth / 10); // Ajustement pour largeur
    drawTextElement(
        page,
        careerPathText,
        fonts.bodyFont,
        styles.bodyStyle,
        rightColumnX,
        currentYRight - 30
    );

    return page;
};

// Helper function for drawing text
const drawTextElement = (page, text, font, style, x, y) => {
    const lines = text.split("\n");
    let currentY = y;

    lines.forEach((line) => {
        const textWidth = font.widthOfTextAtSize(line, style.size);
        const adjustedX = x;

        page.drawText(line, {
            x: adjustedX,
            y: currentY,
            size: style.size,
            font: font,
            color: style.color || rgb(35 / 255, 40 / 255, 58 / 255),
            lineHeight: style.size * 1.3,
        });
        currentY -= style.size * 1.5; // Update Y for next line
    });

    return currentY; // Return updated Y position
};
