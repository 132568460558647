import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {
  calculateTextPosition,
  formatText,
  renderChart,
  analyzeSkillsDifferences,
  addCenteredStyle,
} from "../../utils/utils";

export const createGeneralTemplate = async (
  pdfDoc,
  data,
  backgroundImageUrl,
  fonts,
  jobIndex = 0
) => {
  const pagination = `${((jobIndex + 1) * 6) - (jobIndex)}`;
  const jobTitle = data.results[jobIndex].metier.toUpperCase();
  const page = await createPageTemplate(
    pdfDoc,
    data,
    backgroundImageUrl,
      {
        rightTitle: "ANALYSE GLOBALE",
        leftTitle: formatText(jobTitle,24),
        pagination: pagination,
      }
  );

  const userSkillPools = data.skillPools;
  const job = data.jobs.find(
    (job) => job.title === data.results[jobIndex].metier
  );
  const jobSignature = job.signatures.find(
    (signature) => signature.quiz_code === data.quiz_code
  );

  const jobSkillPools = [
    { name: "Compétences SI", average: calculateAverage(jobSignature, 0, 12) },
    {
      name: "Compétences Générales",
      average: calculateAverage(jobSignature, 12, 27),
    },
    { name: "Relationnel", average: calculateAverage(jobSignature, 27, 33) },
  ];

  const radarChartImageUrl = await renderChart(
    "radar",
    {
      jobSkillPools: jobSkillPools,
      userSkillPools: userSkillPools,
    },
    1
  );

  const radarChartImageBytes = await fetch(radarChartImageUrl).then((res) =>
    res.arrayBuffer()
  );
  const radarChartImageEmbed = await pdfDoc.embedPng(radarChartImageBytes);
  const chartDims = radarChartImageEmbed.scale(1);

  page.drawImage(radarChartImageEmbed, {
    x: layout.pageWidth / 2 - chartDims.width / 2,
    y: layout.pageHeight - layout.margin - chartDims.height - 180,
  });

  const skillAnalysis = analyzeSkillsDifferences(jobSkillPools, userSkillPools);
  const { superiorSkills, inferiorSkills, equalSkills } = skillAnalysis;

  let text = "";
  if (superiorSkills.length > 0)
    text += `Tes compétences en ${superiorSkills.join(
      " et "
    )} dépassent les attentes du métier. `;
  if (inferiorSkills.length > 0)
    text += `Tes compétences en ${inferiorSkills.join(
      " et "
    )} sont inférieures aux attentes du métier. `;
  if (equalSkills.length > 0)
    text += `Tes compétences en ${equalSkills.join(
      " et "
    )} sont égales aux attentes du métier. `;
  if (text === "")
    text =
      "Au global, ton profil présente des forces et des axes d'amélioration.";

  const formattedText = addCenteredStyle(formatText(text));
  const { x, y } = calculateTextPosition(
    formattedText,
    fonts.bodyFont,
    styles.bodyStyle.size,
    layout.pageHeight - chartDims.height - 220,
    "center"
  );

  page.drawText(formattedText, {
    x: x,
    y: y,
    size: styles.bodyStyle.size,
    font: fonts.bodyFont,
    color: styles.bodyStyle.color,
    lineHeight: styles.bodyStyle.size * 1.33,
  });

  return page;
};

const calculateAverage = (signature, start, end) => {
  return (
    (signature.content
      .slice(start, end)
      .split("")
      .reduce((sum, val) => sum + parseInt(val), 0) /
      ((end - start) * 5)) *
    100
  );
};
