import { layout } from "../config/layout";
import { styles } from "../config/styles";
import { loadFonts } from "../config/fonts";
import { calculateTextPosition, loadImage, drawImage } from "../utils/utils";
import { rgb } from "pdf-lib";

const imageCache = new Map();

export const createPageTemplate = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    options = {}
) => {
  const {
    logo = null,
    leftTitle = null,
    rightTitle = null,
    pagination = null,
  } = options;

  const page = pdfDoc.addPage([layout.pageWidth, layout.pageHeight]);
  const fonts = await loadFonts(pdfDoc);

  // Set background image if available
  if (backgroundImageUrl) {
    await drawBackgroundImage(pdfDoc, page, backgroundImageUrl);
  }

  // Draw logo or left title
  if (logo) {
    await drawLogo(pdfDoc, page);
  } else if (leftTitle) {
    drawLeftTitle(page, leftTitle, fonts);
  }

  // Draw right title
  if (rightTitle) {
    drawRightTitle(page, rightTitle, fonts);
  }

  // Add pagination if provided
  if (pagination) {
    drawPagination(page, pagination, fonts);
  }

  return page;
};

// Utility function to draw the background image
const drawBackgroundImage = async (pdfDoc, page, imageUrl) => {
  let imageEmbed;
  if (imageCache.has(imageUrl)) {
    imageEmbed = imageCache.get(imageUrl);
  } else {
    const imageBytes = await loadImage(imageUrl);
    imageEmbed = await pdfDoc.embedPng(imageBytes);
    imageCache.set(imageUrl, imageEmbed);
  }

  const imageWidth = layout.pageWidth;
  const imageHeight = layout.pageHeight;

  drawImage(page, imageEmbed, {
    x: (layout.pageWidth - imageWidth) / 2,
    y: (layout.pageHeight - imageHeight) / 2,
    width: imageWidth,
    height: imageHeight,
  });
};

// Utility function to draw the logo
const drawLogo = async (pdfDoc, page) => {
  const logoPath = `/pdf/images/logo.png`;

  let logoEmbed;
  if (imageCache.has(logoPath)) {
    logoEmbed = imageCache.get(logoPath);
  } else {
    const logoBytes = await loadImage(logoPath);
    logoEmbed = await pdfDoc.embedPng(logoBytes);
    imageCache.set(logoPath, logoEmbed);
  }

  const logoDims = logoEmbed.scale(0.66);
  drawImage(page, logoEmbed, {
    x: layout.margin,
    y: layout.pageHeight - layout.margin - logoDims.height,
    width: logoDims.width,
    height: logoDims.height,
  });
};

// Utility function to draw the left title
const drawLeftTitle = (page, title, fonts) => {
  const fontSize = styles.topTitleStyle.size;
  const { x, y } = calculateTextPosition(
      title,
      fonts.topTitleFont,
      fontSize,
      layout.pageHeight - layout.margin - fontSize,
      "left"
  );
  page.drawText(title, {
    x,
    y,
    size: fontSize,
    font: fonts.topTitleFont,
    color: rgb(35 / 255, 40 / 255, 58 / 255),
    lineHeight: fontSize * 1.2,
  });
};

// Utility function to draw the right title
const drawRightTitle = (page, title, fonts) => {
  const fontSize = styles.topTitleStyle.size;
  const { x, y } = calculateTextPosition(
      title,
      fonts.topTitleFont,
      fontSize,
      layout.pageHeight - layout.margin - fontSize,
      "right"
  );
  page.drawText(title, {
    x,
    y,
    size: fontSize,
    font: fonts.topTitleFont,
    color: rgb(35 / 255, 40 / 255, 58 / 255),
    lineHeight: fontSize * 1.2,
  });
};

// Utility function to draw the pagination
const drawPagination = (page, pagination, fonts) => {
  const fontSize = styles.bodyStyle.size;
  const { x, y } = calculateTextPosition(
      pagination,
      fonts.bodyFont,
      fontSize,
      layout.margin,
      "right"
  );
  page.drawText(pagination, {
    x,
    y,
    size: fontSize,
    font: fonts.bodyFont,
    color: rgb(100 / 255, 100 / 255, 100 / 255),
  });
};
