import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";

const SkillsSection = () => {
    const [topSkills, setTopSkills] = useState<any[]>([]);
    const [dimension, setDimension] = useState<number | null>(null);

    useEffect(() => {
        const fetchTopSkills = async () => {
            const sessionId = localStorage.getItem("quiz_session_id");
            if (!sessionId) return;

            try {
                const response = await fetch("/api/getAnswersResults", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: sessionId,
                        quiz_code: "CIG_SIMP",
                    }),
                });

                const data = await response.json();
                const sortedSkills = data.answers
                    .sort((a: any, b: any) => b.value - a.value)
                    .slice(0, 2);
                setTopSkills(sortedSkills);
                setDimension(data.dimension);
            } catch (error) {
            }
        };

        fetchTopSkills();
    }, []);

    const getDimensionText = () => {
        if (dimension === null) return "";
        if (dimension > 120) {
            return "D'après notre analyse, le métier est sur-dimensionné pour ton profil, tu risques d'être challengé ! 🔼";
        } else if (dimension < 90) {
            return "D'après notre analyse, le métier est sous-dimensionné pour ton profil, tu risques de t'ennuyer... 🔽";
        } else {
            return "D'après notre analyse, le métier est bien dimensionné pour ton profil ! ✅";
        }
    };

    return (
        <section className="flex flex-col justify-center gap-4 mt-4 md:mt-8">
            <p className="max-w-[600px] text-gray-500 text-base">
                Grâce à tes compétences en{" "}
                <span className="font-bold">
          {topSkills[0]?.skill || "[compétence clé]"}
        </span>{" "}
                et ta capacité en{" "}
                <span className="font-bold">
          {topSkills[1]?.skill || "[compétence ou trait personnel]"}
        </span>
                , tu es fait(e) pour exceller dans ce domaine. Prêt(e) à franchir cette
                étape ? 🚀
            </p>
            <p className="max-w-[600px] text-lg font-bold mt-8 underline"
               data-tooltip-id="dimension-tooltip"
               data-tooltip-place="top">
                {getDimensionText()}
            </p>
            <Tooltip id="dimension-tooltip">
                <div className="text-center">
                    <p>La dimension du métier est calculée en fonction</p>
                    <p>de tes compétences par rapport à celles requises</p>
                    <p>pour le métier ainsi que leur importance relative.</p>
                </div>
            </Tooltip>
            <p className="max-w-[600px] text-lg font-bold mt-4">
                Ton parcours est unique, et ce métier reflète parfaitement tes points
                forts. Tu es maintenant à un pas d'une carrière qui te correspond
                vraiment.
            </p>
            <p className="max-w-[600px] text-gray-500 text-base mt-4 font-emphasis">
                Psst, tu peux scroller vers le bas pour découvrir ton classement complet
                et télécharger ton rapport personnalisé !
            </p>
        </section>
    );
};

export default SkillsSection;