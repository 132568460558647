import currentQuestions from "@/data/CIG_SIMP.json";
import { getResultsSelection } from "./sessionStorage";

type AnswerSkillPools = {
  __typename: string;
  quiz_id: string;
  skill_pool: string;
  skill: string;
  signature_index: number;
  value: string;
};

export async function formatResults() {
  const resultsSelection = getResultsSelection();
  return resultsSelection
      .map((result: { job: string; percentage: number, dimension: number }) => ({
        metier: result.job,
        score: parseFloat(result.percentage.toFixed(2)),
        dimension: result.dimension
      }))
      .sort((a: { score: number; }, b: { score: number; }) => b.score - a.score);
}

export async function transformAnswersToSkillPools(
  answers: AnswerSkillPools[]
) {
  const skillPoolMap: { [key: string]: { total: number; count: number } } = {};

  answers.forEach((answer) => {
    const value = parseInt(answer.value);
    if (!skillPoolMap[answer.skill_pool]) {
      skillPoolMap[answer.skill_pool] = { total: 0, count: 0 };
    }
    skillPoolMap[answer.skill_pool].total += value;
    skillPoolMap[answer.skill_pool].count += 1;
  });

  const skillPools = Object.keys(skillPoolMap).map((pool) => {
    const { total, count } = skillPoolMap[pool];
    const average = (total / (count * 5)) * 100;
    return { name: pool, average: Math.round(average) };
  });

  return skillPools;
}

export async function transformAnswers(currentAnswers: any) {
  return currentAnswers.map((answer: any) => {
    const question = currentQuestions.questions.find(
      (q) => q.skill === answer.skill
    );

    if (!question) {
      throw new Error(`Skill ${answer.skill} not found in questions`);
    }

    return {
      __typename: "Answer",
      quiz_id: "CIG_SIMP",
      skill_pool: question.skillPool,
      skill: answer.skill,
      signature_index: question.signatureIndex,
      value: answer.value, // On utilise la valeur correcte
    };
  });
}
