import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { PDFDocument } from "pdf-lib";
import Chart from "chart.js/auto";
import { formatText } from "../../utils/utils";

export const createSkillDetailsTemplate = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    fonts,
    jobIndex = 0
) => {
    const job = data.jobs.find(job => job.title === data.results[jobIndex].metier);
    const jobSignature = job.signatures.find(signature => signature.quiz_code === data.quiz_code).content;

    const skillsGroupedByType = groupSkillsByType(data.answers, jobSignature);

    const pagination = `${((jobIndex + 1) * 7) - (jobIndex)}`;
    const jobTitle = data.results[jobIndex].metier.toUpperCase();
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, {
        rightTitle: formatText("DÉTAILS DES COMPÉTENCES", 20),
        leftTitle: formatText(jobTitle, 24),
        pagination: pagination,
    });

    const radarChartImageUrl = await renderRadarChart(skillsGroupedByType);
    const radarChartImageBytes = await fetch(radarChartImageUrl).then(res => res.arrayBuffer());
    const radarChartImageEmbed = await pdfDoc.embedPng(radarChartImageBytes);
    const chartDims = radarChartImageEmbed.scale(1.2);

    page.drawImage(radarChartImageEmbed, {
        x: layout.pageWidth / 2 - chartDims.width / 2,
        y: layout.pageHeight - layout.margin - chartDims.height - 200,
        width: chartDims.width,
        height: chartDims.height
    });
};

const groupSkillsByType = (userAnswers, jobSignature) => {
    const grouped = userAnswers.reduce((acc, answer) => {
        const { skill_pool, skill, signature_index, value } = answer;
        const requiredLevel = parseInt(jobSignature[signature_index], 10);
        const userLevel = parseInt(value, 10);
        if (!acc[skill_pool]) acc[skill_pool] = [];
        acc[skill_pool].push({ skill, requiredLevel, userLevel });
        return acc;
    }, {});

    // Supprimer les doublons dans chaque groupe de compétences
    Object.keys(grouped).forEach(skillPool => {
        grouped[skillPool] = removeDuplicateSkills(grouped[skillPool]);
    });

    return grouped;
};

const removeDuplicateSkills = (skills) => {
    const seen = new Set();
    return skills.filter(skill => {
        if (seen.has(skill.skill)) {
            return false;
        }
        seen.add(skill.skill);
        return true;
    });
};

const renderRadarChart = async (skillsGroupedByType) => {
    return new Promise((resolve) => {
        const container = document.createElement("div");
        container.style.width = "800px";
        container.style.height = "600px";
        container.style.margin = "0 auto";
        document.body.appendChild(container);

        const canvas = document.createElement("canvas");
        container.appendChild(canvas);
        const ctx = canvas.getContext("2d");

        // Créer la liste complète des compétences à partir de skillsGroupedByType
        const allSkills = Array.from(
            new Set(
                Object.entries(skillsGroupedByType).flatMap(([, skillsData]) =>
                    skillsData.map(skill => skill.skill)
                )
            )
        );

        const datasets = [];

        // Ajouter les séries pour chaque skillPool (Utilisateur)
        Object.entries(skillsGroupedByType).forEach(([skillPool, skillsData], index) => {
            const colorBase = generateColor(index); // Couleur pour le skillPool

            datasets.push({
                label: `${skillPool}`,
                data: allSkills.map(skill => {
                    const skillData = skillsData.find(s => s.skill === skill);
                    return skillData ? skillData.userLevel : 0; // Ajouter 0 si absent
                }),
                backgroundColor: `rgba(${colorBase}, 0.2)`,
                borderColor: `rgba(${colorBase}, 1)`,
                borderWidth: 2,
                pointBackgroundColor: `rgba(${colorBase}, 1)`,
                fill: true,
            });
        });

        // Ajouter la série pour le métier
        datasets.push({
            label: "Compétences requises",
            data: allSkills.map(skill => {
                const skillData = Object.values(skillsGroupedByType)
                    .flat()
                    .find(s => s.skill === skill);
                return skillData ? skillData.requiredLevel : 0;
            }),
            borderColor: "rgba(128, 128, 128, 0.4)",
            borderWidth: 2,
            pointBackgroundColor: "rgba(128, 128, 128, 0.4)",
            fill: false, // Pas de remplissage
        });

        // Créer le radar chart
        new Chart(ctx, {
            type: "radar",
            data: {
                labels: allSkills, // Inclure toutes les compétences
                datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    r: {
                        angleLines: { display: true },
                        suggestedMin: 0,
                        suggestedMax: 5,
                        ticks: { stepSize: 1, display: false },
                        pointLabels: { font: { size: 12 } },
                    },
                },
                plugins: {
                    legend: {
                        position: "top",
                        labels: { font: { size: 12 } },
                    },
                },
            },
        });

        setTimeout(() => {
            const imageUrl = canvas.toDataURL("image/png");
            document.body.removeChild(container);
            resolve(imageUrl);
        }, 1000);
    });
};

const generateColor = (index) => {
    const colors = [
        "225, 1, 105",  // Rose
        "255, 138, 0",  // Orange
        "255, 214, 0",  // Jaune
    ];
    return colors[index % colors.length];
};
