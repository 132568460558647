"use client";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { generatePdf } from "@/lib/pdf/utils/generatePdf";
import { getResultsSelection } from "@/lib/sessionStorage";
import {
  transformAnswersToSkillPools,
  formatResults,
  transformAnswers,
} from "@/lib/dataFormatting";

type FormValues = {
  nom: string;
  prenom: string;
  email: string;
};

const FormResult = () => {
  const router = useRouter();
  const [emailSent, setEmailSent] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    nom: "",
    prenom: "",
    email: "",
  });
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [buttonText, setButtonText] = useState("Obtenir le rapport");
  const [buttonColor, setButtonColor] = useState("bg-[#111213]");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const validateName = (name: string): string | undefined => {
    if (!name) return "Le nom est obligatoire.";
    if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ\- ]+$/.test(name))
      return "Le nom doit contenir que des lettres.";
    return undefined; // Remplacez null par undefined
  };

  const validateEmail = (email: string): string | undefined => {
    if (!email) return "L'email est obligatoire.";
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return "L'email est au mauvais format.";
    return undefined; // Remplacez null par undefined
  };

  const sanitizeInput = (input: string): string => {
    return input.replace(/[<>/\\]/g, "");
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const sanitizedValue = sanitizeInput(value);
    setFormValues({ ...formValues, [name]: sanitizedValue });

    let newErrors = { ...errors };
    if (name === "nom" || name === "prenom") {
      newErrors[name] = validateName(sanitizedValue);
    } else if (name === "email") {
      newErrors.email = validateEmail(sanitizedValue);
    }
    setErrors(newErrors);
  };

  const handleSendPDF = async () => {
    setLoading(true);
    setButtonText("Envoi en cours...");
    setButtonColor("bg-[#111213]"); // Maintien de la couleur noire pendant l'envoi
    try {
      //fetch answers
      const sessionId = localStorage.getItem("quiz_session_id");
      if (!sessionId) return;
      const responseQuiz = await fetch("/api/getAnswersResults", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
        }),
      });
      const data = await responseQuiz.json();
      setProgress(20);

      //fetch updateUserInfo to store nom, prenom, email
      const updateUserInfo = await fetch("/api/updateUserInfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
          name: formValues.prenom,
          surname: formValues.nom,
          email: formValues.email,
        }),
      });
      const updateUserResult = await updateUserInfo.json(); // TODO : Handle exception here
      setProgress(30);

      const resultsQuiz = await formatResults();
      const answersQuiz = await transformAnswers(data.answers);
      const skillPoolsQuiz = await transformAnswersToSkillPools(answersQuiz);

      setButtonText("Génération du PDF...");
      const pdfFile = await generatePdf(
        formValues.prenom,
        "CIG_SIMP",
        resultsQuiz,
        answersQuiz,
        skillPoolsQuiz,
        (progressValue: React.SetStateAction<number>) =>
          setProgress(progressValue)
      );

      const pdfBase64 = Buffer.from(pdfFile).toString("base64");

      setButtonText("Envoi de l'email...");

      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: formValues.email,
          dynamicData: {},
          pdfBase64,
        }),
      });
      setProgress(100);

      // gestion de l'état du bouton"
      if (response.ok) {
        setButtonText("Envoyé !");
        setButtonColor("bg-green-500");

        setTimeout(() => {
          setEmailSent(true);
          router.push("/?emailSent=true");
        }, 2000);
      } else {
        setButtonText("Échec, veuillez réessayer");
        setButtonColor("bg-red-500");

        setTimeout(() => {
          setButtonText("Obtenir le rapport");
          setButtonColor("bg-[#111213]");
        }, 3000);
      }

    } catch (error) {
      setButtonText("Échec, veuillez réessayer");
      setButtonColor("bg-red-500");

      setTimeout(() => {
        setButtonText("Obtenir le rapport");
        setButtonColor("bg-[#111213]");
      }, 3000);
    } finally {
      setLoading(false);
      setTimeout(() => setProgress(0), 3000);
    }
  };

  useEffect(() => {
    const nomError = validateName(formValues.nom);
    const prenomError = validateName(formValues.prenom);
    const emailError = validateEmail(formValues.email);

    if (!nomError && !prenomError && !emailError) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formValues]); // Dépend des valeurs du formulaire

  return (
    <form className="bg-transparent pb-16 px-8 w-full md:w-2/3 flex flex-col justify-center items-center gap-4 mx-auto">
      <div className="flex flex-col gap-4 justify-center items-center mb-4">
        <h1 className="text-xl w-full font-bold text-left text-[#6D4C41]">
          Étape 2/2 - Remplir le formulaire
        </h1>
        <p className="max-w-[600px] text-gray-500 text-base mt-4 font-emphasis text-center">
          Dernière étape : complète les champs et ton rapport te sera envoyé par
          mail !{" "}
        </p>
      </div>

      <div className="flex justify-center items-center gap-4 flex-row w-full">
        <div className="w-full">
          <label className="block text-gray-700">Nom</label>
          <input
            type="text"
            name="nom"
            value={formValues.nom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border ${
              errors.nom ? "border-red-500" : "border-gray-300"
            } rounded`}
          />
          {errors.nom && <p className="text-red-500 text-sm">{errors.nom}</p>}
        </div>

        <div className="w-full">
          <label className="block text-gray-700">Prénom</label>
          <input
            type="text"
            name="prenom"
            value={formValues.prenom}
            onChange={handleChange}
            className={`w-full px-3 py-2 border ${
              errors.prenom ? "border-red-500" : "border-gray-300"
            } rounded`}
          />
          {errors.prenom && (
            <p className="text-red-500 text-sm">{errors.prenom}</p>
          )}
        </div>
      </div>

      <div className="w-full">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
          className={`w-full px-3 py-2 border ${
            errors.email ? "border-red-500" : "border-gray-300"
          } rounded`}
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>

      {loading && (
        <div className="w-full h-2.5 bg-gray-300 rounded-full">
          <div
            className="h-2.5 transition-all duration-200 rounded-full bg-gradient-to-r from-[#F9C50C] to-[#AB1058]"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}

      <div className="overflow-hidden rounded-lg shadow max-w-[16rem] my-2">
        <button
          type="button"
          onClick={handleSendPDF}
          disabled={!isFormValid || loading}
          className={`w-full flex items-center justify-center px-8 py-2 text-base text-white ${buttonColor}`}
        >
          {buttonText} {loading && `(${progress}%)`}
        </button>
      </div>
    </form>
  );
};

export default FormResult;
