/**
    * Calcule l'aire totale pour une signature donnée
    * @param {string} signature - La signature à traiter
    * @returns {number} - L'aire totale
 */
export function calcArea(signature) {
    let area = 0;
    for (let i = 0; i < signature.length; i++) {
        const nextValue = i === signature.length - 1 ? signature[0] : signature[i + 1];
        area += signature[i] * nextValue * (Math.sin(2 * Math.PI / 33) / 2);
    }
    return area;
}


/**
 * Donne le dimmensionnement de la signature pour un métier donné
 * @param {string} signature - La signature du métier à traiter
 * @param {string} userSignature - La signature de l'utilisateur
 * @returns {number} - Le dimmensionnement
 */
export function getDimension(userSignature, signature) {
    const userArea = calcArea(userSignature);
    const jobArea = calcArea(signature);
    return (Math.sqrt(jobArea)/Math.sqrt(userArea))*100;
}
