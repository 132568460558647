import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import { calculateTextPosition } from "../../utils/utils";

export const createSommairePage = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    fonts
) => {
  const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, {pagination: "2"});

  const title = "Sommaire";
  const startYTitle = layout.pageHeight - layout.margin - 100;

  const { x: titleX, y: titleY } = calculateTextPosition(
      title,
      fonts.topTitleFont,
      styles.titleStyle.size,
      startYTitle,
      "center"
  );

  page.drawText(title, {
    x: titleX,
    y: titleY,
    size: styles.titleStyle.size * 1.33,
    font: fonts.topTitleFont,
    color: styles.titleStyle.color,
    lineHeight: styles.titleStyle.size * 1.2,
  });

  const metiersText =
      data.results.length === 1
          ? "• Tes résultats pour le métier choisi"
          : `• Tes résultats pour les ${data.results.length} métiers choisis`;

  const text = [
    {
      content: metiersText,
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.5,
      weight: "bold",
    },
    { content: "", font: fonts.bodyFont, size: styles.bodyStyle.size },
    {
      content: "• Tes détails de résultats (par métier) :",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.5,
      weight: "bold",
    },
    {
      content: "  - Groupes de compétences forts",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
    {
      content: "  - Forces & Faiblesses de ton profil",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
    {
      content: "  - Synthèse profil & axes d’amélioration",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
    {
      content: "  - Fiche Métier",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
    { content: "", font: fonts.bodyFont, size: styles.bodyStyle.size },
    {
      content: "• Synthèse des résultats pour 50 métiers :",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.5,
      weight: "bold",
    },
    {
      content: "  - Correspondance par type de métier",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
    {
      content: "  - Correspondance par métier",
      font: fonts.bodyFont,
      size: styles.bodyStyle.size * 1.2,
      weight: "normal",
    },
  ];

  let currentY = startYTitle - 200;

  text.forEach(({ content, font, size, weight }) => {
    const { x } = calculateTextPosition(content, font, size, currentY, "left");
    page.drawText(content, {
      x,
      y: currentY,
      size,
      font,
      color: styles.bodyStyle.color,
      lineHeight: size * 1.4,
      fontWeight: weight,
    });

    currentY -= size * 1.6;
  });

  return page;
};
