import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import {
  addCenteredStyle,
  calculateTextPosition,
  formatText,
  renderChart,
} from "../../utils/utils";
import { styles } from "../../config/styles";

export const createTopPage = async (
  pdfDoc,
  data,
  backgroundImageUrl,
  fonts
) => {
  const pageTitle = formatText(
    `RÉSULTATS généraux - ${data.name.toUpperCase()}`,
    30
  );
  const page = await createPageTemplate(
    pdfDoc,
    data,
    backgroundImageUrl,
      { leftTitle: pageTitle, pagination: "3" }
  );

  const chartData = {
    results: data.results.map((result, index) => ({
      metier: data.results[index].metier,
      score: result.score,
    })),
  };

  const barChartImageUrl = await renderChart("bar", chartData, 1, {
    plugins: {
      x: {
        title: { display: false, text: "Métiers" },
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          font: { size: 32 },
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: { display: true, text: "Score (%)" },
        grid: { display: true },
      },
      legend: {
        display: false,
        labels: { font: { size: 24 } },
      },
      title: {
        display: false,
        text: "Correspondance des métiers",
      },
      datalabels: {
        anchor: "center",
        align: "center",
        color: "#fff",
        font: {
          size: 32,
          weight: "bold",
        },
        formatter: (value, context) => {
          const label = data.results[context.dataIndex].metier;
          return `${label}: ${value}%`;
        },
      },
    },
  });

  const barChartImageBytes = await fetch(barChartImageUrl).then((res) =>
    res.arrayBuffer()
  );
  const barChartImageEmbed = await pdfDoc.embedPng(barChartImageBytes);
  const chartDims = barChartImageEmbed.scale(1);

  page.drawImage(barChartImageEmbed, {
    x: layout.pageWidth / 2 - chartDims.width / 2,
    y: layout.pageHeight - layout.margin - chartDims.height - 100,
    width: chartDims.width,
    height: chartDims.height,
  });

  let text;
  if (data.results.length === 1) {
    text =
      "Retrouvez la correspondance en\n" +
      "pourcentage pour le métier que\n" +
      "vous avez sélectionné.\n";
  } else if (data.results.length === 2) {
    text =
      "Retrouvez la correspondance en\n" +
      "pourcentage entre le métier pour lequel\n" +
      "Ton profil a la meilleure adéquation, suivi\n" +
      "de l'autre métier choisi.\n";
  } else {
    text =
      "Retrouvez la correspondance en\n" +
      "pourcentage entre le métier pour lequel\n" +
      "ton profil a la meilleure adéquation, suivi\n" +
      `des ${data.results.length - 1} autres métiers choisis.\n`;
  }

  text = addCenteredStyle(text);

  const bottomChartYPosition =
    layout.pageHeight - layout.margin - chartDims.height - 100;

  const { x, y } = calculateTextPosition(
    text,
    fonts.bodyFont,
    styles.bodyStyle.size,
    bottomChartYPosition - 40,
    "center"
  );

  page.drawText(text, {
    x: x,
    y: y,
    size: styles.bodyStyle.size,
    font: fonts.bodyFont,
    color: styles.bodyStyle.color,
    lineHeight: styles.bodyStyle.size * 1.33,
    alignment: "center",
  });

  return page;
};
