import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { formatText } from "../../utils/utils";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const createJobSynthesisTemplates = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    fonts
) => {
    const groupedJobs = {
        underDimensioned: [],
        wellDimensioned: [],
        overDimensioned: [],
    };

    data.forEach((job) => {
        if (job.dimension < 90) {
            groupedJobs.underDimensioned.push(job);
        } else if (job.dimension > 120) {
            groupedJobs.overDimensioned.push(job);
        } else {
            groupedJobs.wellDimensioned.push(job);
        }
    });

    const groupTitles = {
        underDimensioned: "Métiers Sous-Dimensionnés",
        wellDimensioned: "Métiers Bien Dimensionnés",
        overDimensioned: "Métiers Sur-Dimensionnés",
    };

    for (const [group, jobs] of Object.entries(groupedJobs)) {
        if (jobs.length > 0) {
            const sortedJobs = jobs.sort((a, b) => b.percentage - a.percentage);
            await createJobSynthesisTemplate(
                pdfDoc,
                sortedJobs,
                backgroundImageUrl,
                fonts,
                groupTitles[group]
            );
        }
    }
};

const createJobSynthesisTemplate = async (
    pdfDoc,
    jobs,
    backgroundImageUrl,
    fonts,
    title
) => {
    const page = await createPageTemplate(pdfDoc, {}, backgroundImageUrl, {
        leftTitle: "SYNTHÈSE",
        rightTitle: title.toUpperCase(),
        pagination: `${jobs.length} Métiers`,
    });

    const barChartImageUrl = await generateBarChartImage(jobs);

    const barChartImageBytes = await fetch(barChartImageUrl).then((res) =>
        res.arrayBuffer()
    );
    const barChartImageEmbed = await pdfDoc.embedPng(barChartImageBytes);
    const chartDims = barChartImageEmbed.scale(1);

    page.drawImage(barChartImageEmbed, {
        x: layout.pageWidth / 2 - chartDims.width / 2,
        y: layout.pageHeight / 2 - chartDims.height / 2 - 20,
        width: chartDims.width,
        height: chartDims.height,
    });

    return page;
};

const generateBarChartImage = async (jobs) => {
    return new Promise((resolve) => {
        const container = document.createElement("div");
        container.style.width = "800px";
        container.style.height = "600px";
        container.style.padding = "20px";
        document.body.appendChild(container);

        const canvas = document.createElement("canvas");
        container.appendChild(canvas);

        const ctx = canvas.getContext("2d");

        Chart.register(ChartDataLabels);

        const accessibleJobs = jobs.filter((job) => job.percentage >= 80);
        const nonAccessibleJobs = jobs.filter((job) => job.percentage < 80);

        const allLabels = jobs.map((job) => job.job);
        const accessibleData = allLabels.map((label) => {
            const job = accessibleJobs.find((job) => job.job === label);
            return job ? job.percentage : null; // Use null for non-accessible jobs
        });

        const nonAccessibleData = allLabels.map((label) => {
            const job = nonAccessibleJobs.find((job) => job.job === label);
            return job ? job.percentage : null; // Use null for accessible jobs
        });

        new Chart(ctx, {
            type: "bar",
            data: {
                labels: allLabels,
                datasets: [
                    {
                        label: "Métiers accessibles",
                        data: accessibleData,
                        backgroundColor: "rgba(225, 1, 105, 0.6)",
                        borderColor: "rgba(225, 1, 105, 1)",
                        borderWidth: 1,
                    },
                    {
                        label: "Métiers non compatibles",
                        data: nonAccessibleData,
                        backgroundColor: "rgba(255, 142, 49, 0.6)",
                        borderColor: "rgba(255, 142, 49, 1)",
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        min: 0,
                        max: 100,
                        ticks: {
                            stepSize: 20,
                            font: {
                                size: 16,
                            },
                        },
                    },
                    y: {
                        ticks: {
                            autoSkip: false,
                            font: {
                                size: 14,
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                        position: "top",
                        labels: {
                            font: {
                                size: 16,
                            },
                        },
                    },
                    datalabels: {
                        display: false
                    }
                    /*
                    datalabels: {
                        anchor: "center",
                        align: "center",
                        color: "#fff",
                        font: {
                            size: 18,
                            weight: "bold",
                        },
                        formatter: (value, context) => {
                            return value !== null
                                ? `${context.chart.data.labels[context.dataIndex]} (${value.toFixed(
                                    2
                                )}%)`
                                : "";
                        },
                    },

                    */
                },
                categoryPercentage: 1,
                barPercentage: 1,
            },
        });

        setTimeout(() => {
            const imageUrl = canvas.toDataURL("image/png");
            document.body.removeChild(container); // Supprimer le conteneur
            resolve(imageUrl);
        }, 1000);
    });
};
